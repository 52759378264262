<template>
  <div class="cost-info">
    <h3 class="cost-title">费用标准</h3>
    <div
      v-for="item in list"
      :key="item.id"
      class="cost-item"
    >
      <div class="cost-item-key">{{ item.key }}</div>
      <div class="cost-item-value">{{ item.value }}</div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CostInfo',

  props: {
    list: Array
  },

  data () {
    return {

    }
  },
  methods: {

  }

}

</script>

<style lang="scss" scoped>

.cost-info {
  margin: 46px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  .cost-title {
    margin-bottom: 10px;
    height: 21px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    line-height: 30px;
    color: #363636;
  }
  .cost-item {
    flex: 0 0 auto;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .cost-item-key,
    .cost-item-value {
      margin-right: 56px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      line-height: 30px;
      color: #3EBC6C;
    }
    .cost-item-key {
      width: 280px;
    }
  }
}

</style>
