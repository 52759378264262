<template>
  <div class="service-container">
    <LevelInfo
      :title="title"
    />
    <ContentInfo
      :content="content"
    />
    <CostInfo
      :list="list"
    />
  </div>
</template>

<script>

import LevelInfo from '@/components/service/LevelInfo.vue'
import ContentInfo from '@/components/service/ContentInfo.vue'
import CostInfo from '@/components/service/CostInfo.vue'

export default {
  name: 'Service',

  components: {
    LevelInfo,
    ContentInfo,
    CostInfo,
  },

  data () {
    return {
      // 标题
      title: '',
      // 内容
      content: '',
      // 费用标准
      list: []
    }
  },

  watch: {
    $route (newData) {
      this.initData(newData)
    }
  },

  created () {
    this.initData(this.$route)
  },

  methods: {
    // 初始化数据
    initData (route) {
      // 标题
      let title = ''
      // 内容
      let content = ''
      // 费用标准
      let list = []
      const type = route.query.type
      if (type === 'kitchen') { // 厨余
        title = '厨余垃圾回收'
        content = '厨余垃圾是指居民日常生活及食品加工、饮食服务、单位供餐等活动中产生的垃圾，包括丢弃不用的菜叶、剩菜、剩饭、果皮、蛋壳、茶渣、骨头等'
        list = [
          {
            id: '1',
            key: '大桶(240L)',
            value: '50.00元'
          },
          {
            id: '2',
            key: '小桶(120L)',
            value: '25.00元'
          },
        ]
      } else if (type === 'build') { // 建筑
        title = '建筑垃圾回收'
        content = '建筑垃圾是指建设、施工单位或个人对各类建筑物、构筑物、管网等进行建设、铺设或拆除、修缮过程中所产生的渣土、弃土、弃料、淤泥及其他废弃物。'
        list = [
          {
            id: '1',
            key: '车(5吨)',
            value: '1000.00元'
          }
        ]
      } else if (type === 'green') { // 绿化
        title = '绿化垃圾回收'
        content = '绿化垃圾，主要是指在修剪树木过程中产生的树木枝干、落叶、草屑等。'
        list = [
          {
            id: '1',
            key: '车(5吨)',
            value: '1000.00元'
          },
        ]
      } else if (type === 'big') { // 大件
        title = '大件垃圾回收'
        content = '大件垃圾是指体积较大、整体性强，需要拆分再处理的废弃物品。包括废家用电器和家具等。'
        list = [
          {
            id: '1',
            key: '床',
            value: '80.00元/套'
          },
          {
            id: '2',
            key: '床垫',
            value: '50.00元/套'
          },
          {
            id: '3',
            key: '沙发（单人，双人）',
            value: '50.00元/套'
          },
          {
            id: '4',
            key: '沙发（三人）',
            value: '100.00元/套'
          },
          {
            id: '5',
            key: '沙发（三人以上）',
            value: '150.00元/套'
          },
          {
            id: '6',
            key: '桌子（木质体积1立方米以下）',
            value: '50.00元/套'
          },
          {
            id: '7',
            key: '桌子（木质体积1立方米以上）',
            value: '100.00元/套'
          },
          {
            id: '8',
            key: '桌子（金属和石材材质1立方米以下）',
            value: '150.00元/套'
          },
          {
            id: '9',
            key: '桌子（金属和石材材质1立方米以上）',
            value: '200.00元/套'
          },
          {
            id: '10',
            key: '椅子（木质及轻便塑料）',
            value: '10.00元/把'
          },
          {
            id: '11',
            key: '椅子（皮/布类合椅）',
            value: '30.00元/把'
          },
          {
            id: '12',
            key: '其他',
            value: '50.00元/小推车'
          },
        ]
      } else if (type === 'recyclable') { // 可回收物
        title = '可回收物回收'
        content = '可回收物指适宜回收利用和资源化利用的生活废弃物。可回收物主要品种包括：废纸、废弃塑料瓶、废金属、废包装物、废旧纺织物、废弃电器电子产品、废玻璃、废纸塑铝复合包装等'
        list = [
          {
            id: '1',
            key: '衣物',
            value: '0.5元/kg'
          },
          {
            id: '2',
            key: '鞋子/包包',
            value: '1.00元/kg'
          },
          {
            id: '3',
            key: '废纸',
            value: '1.00元/kg'
          },
          {
            id: '4',
            key: '黄铜',
            value: '22.00元/kg'
          },
          {
            id: '5',
            key: '铁',
            value: '1.60元/kg'
          },
          {
            id: '6',
            key: '铝合金',
            value: '3.50元/kg'
          },
          {
            id: '7',
            key: '塑料',
            value: '1.20元/kg'
          },
        ]
      } else { // 其他则展示厨余
        title = '厨余垃圾回收'
        content = '厨余垃圾是指居民日常生活及食品加工、饮食服务、单位供餐等活动中产生的垃圾，包括丢弃不用的菜叶、剩菜、剩饭、果皮、蛋壳、茶渣、骨头等'
        list = [
          {
            id: '1',
            key: '大桶(240L)',
            value: '50.00元'
          },
          {
            id: '2',
            key: '小桶(120L)',
            value: '25.00元'
          },
        ]
      }
      this.title = title
      this.content = content
      this.list = list
      console.log('数据: ', title)
    }
  }

}

</script>

<style lang="scss" scoped>

</style>
