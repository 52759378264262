<template>
  <div class="content-info">
    {{ content }}
  </div>
</template>

<script>

export default {
  name: 'ContentInfo',

  props: {
    content: String
  },

  data () {
    return {

    }
  },
  methods: {

  }

}

</script>

<style lang="scss" scoped>

.content-info {
  width: 100%;
  box-sizing: border-box;
  padding: 22px 25px;
  background: #EDF4F8;
  border-radius: 15px;
  text-align: left;
  font-size: 16px;
  font-family: Microsoft YaHei;
  line-height: 30px;
  color: #3B3B3B;
  text-indent: 2rem;
}

</style>
